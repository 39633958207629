import React from 'react';
import { Text } from 'grommet';

export const Disclaimer = () => (
  <Text className="text disclaimer" size="small">
    {'The information on this website is for educational purposes only and does not constitute financial, accounting, or legal advice. We do not recommended any financial decisions. All opinions are my own.\n'}

    {'This site uses affiliate links to generate revenue. If you click and/or make a purchase through affiliate links on this site, I may receive a small payment at no extra cost to you.\n'}

    {'This application is completely client side. Nothing you enter will be sent to a server.\n'}
  </Text>
)
