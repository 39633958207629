import React, { useState } from 'react';
import {
  Text,
} from 'grommet';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { futureValue, futureValueSeries } from 'functions/calculations';
import { formatCurrency } from 'helpers/formatter';
import SliderInput from 'components/calculators/SliderInput';
import ResetButton from 'components/shared/ResetButton';

const FutureValue = () => {
  const [initialAmount, setInitialAmount] = useState(0);
  const [payments, setPayments] = useState(0);
  const [years, setYears] = useState(0);
  const [growthRate, setGrowthRate] = useState(0);
  const fv = +futureValue(initialAmount, payments, growthRate, years).toFixed(2);

  const reset = () => {
    setInitialAmount(0);
    setPayments(0);
    setYears(0);
    setGrowthRate(0);
  }

  return (
    <div className="calculator-container">
      <div>
        <Text>Initial Amount</Text>
        <SliderInput
          value={initialAmount}
          fn={setInitialAmount}
          max={50000}
        />
      </div>
      <div>
        <Text>Payments</Text>
        <SliderInput
          value={payments}
          fn={setPayments}
          max={50000}
        />
      </div>
      <div>
        <Text>Years</Text>
        <SliderInput
          value={years}
          fn={setYears}
          max={200}
          step={1}
        />
      </div>
      <div>
        <Text>Growth Rate</Text>
        <SliderInput
          value={growthRate}
          fn={setGrowthRate}
          max={100}
          step={.1}
          isFloat
        />
      </div>
      { fv ?
        <div>
          <div className="text-row">
            <Text>Future value: </Text>
            <Text weight="bold">${formatCurrency(fv)}</Text>
          </div>
          <ResetButton className="calculator-reset" resetFn={reset} />
        </div> : null
      }
      <div className="calculator-chart">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={futureValueSeries(initialAmount, payments, growthRate, years)}>
            <Line type="monotone" dataKey="total" stroke="#048A81" />
            <Line type="monotone" dataKey="principal" stroke="#06D6A0" />
            <Line type="monotone" dataKey="interest" stroke="#54C6EB" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="time" />
            <YAxis tickFormatter={(value) => `$${formatCurrency(value)}`} />
            <Tooltip formatter={(value) => `$${formatCurrency(value)}`} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default FutureValue;
