import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  RangeInput,
} from 'grommet';

const SliderInput = ({
  value,
  fn,
  min,
  max,
  step,
  isFloat,
}) => {
  const validateInput = input => input && input >= min && input <= max;
  const formatInput = input => input || 0;
  const parser = num => isFloat ? parseFloat(num) : parseInt(num, 10);

  const handleInput = (input) => {
    var num = 0;
    if (input) {
      if (input >= min && input <= max) {
        num = input
      }
      else {
        return
      }
    }
    fn(isFloat ? parseFloat(num) : parseInt(num, 10));
  }

  return (
    <div>
      <TextInput
        value={Number(value).toString()}
        type="number"
        min={min}
        max={max}
        onChange={(event) => handleInput(event.target.value)}
      />
      <RangeInput
        max={max}
        min={min}
        step={step}
        value={value}
        onChange={(event) => handleInput(event.target.value)}
      />
    </div>
  )
};

SliderInput.propTypes = {
  value: PropTypes.number.isRequired,
  fn: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  isFloat: PropTypes.bool,
};

SliderInput.defaultProps = {
  min: 0,
  max: 20000,
  step: 100,
  isFloat: false
}

export default SliderInput;
