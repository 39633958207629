import React, { useState } from 'react';
import {
  Text,
  Select,
  TextInput,
} from 'grommet';
import { pmt, interestPaid } from 'functions/calculations';
import { formatCurrency } from 'helpers/formatter';

const AutoLoan = () => {
  const [autoPrice, setAutoPrice] = useState(35000);
  const [tradeIn, setTradeIn] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [term, setTerm] = useState(48);
  const [rate, setRate] = useState(3.5);

  const TERM_OPTIONS = [12, 24, 36, 48, 60, 72];

  const loanAmount = () => autoPrice - tradeIn - downPayment;

  return (
    <div className="calculator-container">
      <div className="calculator-inputSection">
        <Text>Vehicle Price</Text>
        <TextInput
          value={Number(autoPrice).toString()}
          type="number"
          onChange={(event) => setAutoPrice(event.target.value)}
        />
      </div>
      <div className="calculator-inputSection">
        <Text>Trade In</Text>
        <TextInput
          value={Number(tradeIn).toString()}
          type="number"
          onChange={(event) => setTradeIn(event.target.value)}
        />
      </div>
      <div className="calculator-inputSection">
        <Text>Down Payment</Text>
        <TextInput
          value={Number(downPayment).toString()}
          type="number"
          onChange={(event) => setDownPayment(event.target.value)}
        />
      </div>
      <div className="calculator-inputSection">
        <Text>Term</Text>
        <Select
          options={TERM_OPTIONS}
          value={term}
          labelKey={value => `${value} months`}
          onChange={({ option }) => setTerm(option)}
        />
      </div>
      <div className="calculator-inputSection">
        <Text>Interest Rate (APR)</Text>
        <TextInput
          value={Number(rate).toString()}
          type="number"
          onChange={(event) => setRate(event.target.value)}
        />
      </div>
      <Text className="text">
        You will pay ${formatCurrency(pmt(loanAmount(), rate / (12 * 100), term))} a month for {term} months
        and pay ${formatCurrency(interestPaid(loanAmount(), rate / (12 * 100), term))} in interest.
      </Text>
    </div>
  );
}

export default AutoLoan;
