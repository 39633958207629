import React from 'react';
import './App.css';
import theme from 'styles/theme';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import {
  Grommet,
  Header,
  Button,
  Heading,
  Anchor,
  ResponsiveContext,
  Menu,
  Box,
  Footer
} from 'grommet';
import { Menu as MenuIcon } from 'grommet-icons';

import FutureValue from 'components/calculators/FutureValue';
import Mortgage from 'components/calculators/Mortgage';
import DownPayment from 'components/calculators/DownPayment';
import AutoLoan from 'components/calculators/AutoLoan';
import { Disclaimer } from 'components/Disclaimer';

function App() {
  return (
    <Router>
      <Grommet theme={theme}>
        <Header background="brand" pad={{ horizontal: 'small', vertical: 'small' }}>
          <Link to="/">
            <Button
              primary
              label="Home"
            />
          </Link>
          <ResponsiveContext.Consumer>
            {size => size === "small" ?
              (
                <Menu
                  a11yTitle="Navigation Menu"
                  dropProps={{ align: { top: 'bottom', right: 'right' } }}
                  icon={<MenuIcon color="accent-1" />}
                  items={[
                    {
                      label: <Box pad="small">Future Value</Box>,
                      href: '/future_value',
                    },
                    {
                      label: <Box pad="small">Mortgage</Box>,
                      href: '/mortgage',
                    },
                  ]}
                />
              ) :
              (
                <>
                  <Anchor href='/future-value' label="Future Value" />
                  <Anchor href='/mortgage' label="Mortgage" />
                  <Anchor href='/down-payment' label="Down Payment" />
                  <Anchor href='/auto-loan' label="Auto Loan" />
                  <Heading>
                    {process.env.REACT_APP_NAME}
                  </Heading>
                </>
              )
            }
          </ResponsiveContext.Consumer>
        </Header>
        <Box align="center">
          <Switch>
            <Route path="/mortgage">
              <Mortgage />
            </Route>
            <Route path="/future-value">
              <FutureValue />
            </Route>
            <Route path="/down-payment">
              <DownPayment />
            </Route>
            <Route path="/auto-loan">
              <AutoLoan />
            </Route>
            <Route path="/">
              <FutureValue />
            </Route>
          </Switch>
        </Box>
        <Footer>

          <Disclaimer />
        </Footer>
      </Grommet>
    </Router>
  );
}

export default App;
