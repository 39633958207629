export const futureValueAnnuity = (pmt, rate, time) => {
  if (rate === 0) {
    return pmt * time
  } else {
    return pmt * (((1 + (rate / 100)) ** time - 1) / (rate/100))
  }
};

export const futureValueInv = (pv, rate, time) => (
  pv * ((1 + (rate / 100)) ** time)
);

export const futureValue = (pv, pmt, rate, time) => (
  futureValueAnnuity(pmt, rate, time) + futureValueInv(pv, rate, time)
);

export const futureValueSeries = (pv, pmt, rate, time) => {
  return [...Array(time + 1)].map((_, idx) =>
    {
      const fv = +futureValue(pv, pmt, rate, idx).toFixed(2);
      const principal = pv + pmt*idx;
      return {
        time: idx,
        principal,
        total: fv,
        interest: +(fv - principal).toFixed(2)
      }
    }
  );
}

export const pmt = (pv, r, n) => {
  return (r ? (pv * r) / (1 - ((1 + r) ** -n)) : pv / n).toFixed(2);
};

export const interestPaid = (pv, r, n) => (
  ((pmt(pv, r, n) * n) - pv).toFixed(2)
);
