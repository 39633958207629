import React, { useState } from 'react';
import {
  Text,
  TextInput,
} from 'grommet';
import { formatCurrency } from 'helpers/formatter';

const DownPayment = () => {
  const [loan, setLoan] = useState(0);
  const [percentage, setPercentage] = useState(0);

  return (
    <div className="calculator-container">
      <div className="calculator-splitContainer">
        <div className="calculator-inputSection">
          <Text>Loan Amount</Text>
          <TextInput
            value={Number(loan).toString()}
            type="number"
            onChange={(event) => setLoan(event.target.value)}
          />
        </div>
        <div className="calculator-inputSection">
          <Text>Down Payment Percentage (%)</Text>
          <TextInput
            value={Number(percentage).toString()}
            type="number"
            onChange={(event) => setPercentage(event.target.value)}
          />
        </div>
      </div>
      <Text className="text">
        A {percentage}% down payment will be ${formatCurrency(loan * percentage / 100)}
      </Text>
    </div>
  )
}

export default DownPayment;
