import React, { useState } from 'react';
import {
  Text,
  Select,
  TextInput,
} from 'grommet';
import { pmt, interestPaid } from 'functions/calculations';
import { formatCurrency } from 'helpers/formatter';

const Mortgage = () => {
  const [homePrice, setHomePrice] = useState(400000);
  const [term, setTerm] = useState(30);
  const [rate, setRate] = useState(3.5);

  const TERM_OPTIONS = [15, 30];

  return (
    <div className="calculator-container">
      <div className="calculator-inputSection">
        <Text>Home Price</Text>
        <TextInput
          value={Number(homePrice).toString()}
          type="number"
          onChange={(event) => setHomePrice(event.target.value)}
        />
      </div>
      <div className="calculator-inputSection">
        <Text>Down Payment (20%) </Text>
        <Text weight="bold">
          ${formatCurrency((homePrice * .2).toFixed(2))}
        </Text>
      </div>
      <div className="calculator-inputSection">
        <Text>Term</Text>
        <Select
          options={TERM_OPTIONS}
          value={term}
          labelKey={value => `${value} years`}
          onChange={({ option }) => setTerm(option)}
        />
      </div>
      <div className="calculator-inputSection">
        <Text>Interest Rate (APR)</Text>
        <TextInput
          value={Number(rate).toString()}
          type="number"
          onChange={(event) => setRate(event.target.value)}
        />
      </div>
      <Text className="text">
        You will pay ${formatCurrency(pmt(homePrice, rate / (12 * 100), term * 12))} a month for {term} years
        and pay ${formatCurrency(interestPaid(homePrice, rate / (12 * 100), term * 12))} in interest.
      </Text>
    </div>
  );
}

export default Mortgage;
