import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'grommet';
import { Refresh } from 'grommet-icons';

const ResetButton = ({ resetFn, className }) => (
  <Button className={className} onClick={resetFn} icon={<Refresh />} primary />
);

ResetButton.propTypes = {
  resetFn: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ResetButton.defaultProps = {
  className: ''
}

export default ResetButton;
